import React from 'react';
import { Divider } from 'antd';

import './eightZone.scss';

const EightZone = (props) => {
  const { data } = props;

  if (!data?.entity.fieldZoneTitre) {
    return null;
  }

  return (
    <div className="container eight-zone--section">
      <Divider plain className="consumables--divider">
        {data.entity.fieldZoneTitre}
      </Divider>
      <div className="boards--wrapper">
        {data.entity.fieldZoneConseil.map(({ entity }) => (
          <div className="board" key={entity.fieldConseilTitre}>
            <h3 className="board--title">
              <img className="board--icon" src={entity.fieldConseilIcone.entity.fieldMediaImage.url} alt={entity.fieldConseilTitre} />
              {entity.fieldConseilTitre}
            </h3>
            <div className="board--desc" dangerouslySetInnerHTML={{ __html: entity.fieldConseilTexte.processed }} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default EightZone;
