import React from 'react';

import './thirdZone.scss';

const ThirdZone = (props) => {
  const { data } = props;

  if (!data?.entity.fieldZoneTitre) {
    return null;
  }

  return (
    <div className="third-zone--section">
      <div className="container">
        <div className="third-zone--wrapper">
          <div className="third-zone--content--wrapper">
            <h1 className="third-zone--title">
              <img src={data.entity.fieldZoneIcone.entity.fieldMediaImage.url} alt="Universe icon" className="third-zone--icon"/>
              {data.entity.fieldZoneTitre}
            </h1>
            <div className="third-zone--desc" dangerouslySetInnerHTML={{ __html: data.entity.fieldZoneTexte.processed }} />
            <div className="third-zone--desc" dangerouslySetInnerHTML={{ __html: data.entity.fieldZoneDeuxiemeTexte.processed }} />
          </div>
          <div className="third-zone--image--wrapper">
            <img src={data.entity.fieldZoneImage.entity.fieldMediaImage.url} alt={data.entity.fieldZoneTitre} className="third-zone--image" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThirdZone;
