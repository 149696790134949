import React from 'react';
import { Modal, Steps, Select } from 'antd';

import CaliceoButton from 'components/buttons/CaliceoButton';

import './spaBookingFirstStepModal.scss';

const { Step } = Steps;

const ChooseFromPackModal = (props) => {
  const {
    showFirstStepBooking,
    setShowFirstStepBooking,
    offer,
    setSelectedOid,
    setGoToNextStepBooking,
    productName,
    setSelectedFromFirstStep,
  } = props;

  const confirmFirstStepChoice = () => {
    setShowFirstStepBooking(false);
    setSelectedFromFirstStep(true);
    setGoToNextStepBooking(true);
  };

  

  if (!showFirstStepBooking || !offer?.entity) return null;

  var firstComboBoxItem = offer.entity.fieldConsommablesList.find(({ entity }) => entity.fieldRefEntite?.entity.fieldCombobox === '1');
  var secondComboBoxItems = offer.entity.fieldConsommablesList.filter(({ entity }) => entity.fieldRefEntite?.entity.fieldCombobox === '2');
   


   if(!firstComboBoxItem) {
      if(offer.entity.fieldConsommablesList.length > 0) {
        secondComboBoxItems = offer.entity.fieldConsommablesList;
      }
   }


  if (!firstComboBoxItem && (secondComboBoxItems.length ===0)) return null;

  return (
    <Modal
      visible={showFirstStepBooking}
      onCancel={() => setShowFirstStepBooking()}
      className="offers-modal first-step-booking-modal"
      footer={null}
      width="60%"
    >
      <h2>Personnalisez votre<br />parenthèse bien-être</h2>
      <Steps size="small" style={{ margin: '20px 0' }}>
        <Step title="Ma prestation" />
        <Step title="Choix de mon créneau" />
      </Steps>
      <p className="text-left">
        Votre prestation est personnalisable. Nous vous invitons à choisir votre soin dans le menu déroulant pour poursuivre votre réservation.
      </p>
      <p className="text-left mb-2">Votre offre {productName} comprend :</p>
      {!firstComboBoxItem ? (
        <div className="d-flex align-items-center mb-3">
          <p className="mb-0">
            Prestation 1 :
          </p>
          <Select
            size="large"
            placeholder="Veuillez sélectionner"
            onSelect={(value) => setSelectedOid(value)}
            dropdownAlign={{ offset: [0, 0] }}
            dropdownMatchSelectWidth={false}
            dropdownClassName="gift-modal--form--who--field--dropdown"
            className="gift-modal--form--who--field gift-modal--form--field ml-3"
          >
         
            {secondComboBoxItems.map(({ entity }) => {
              if(entity.fieldRefEntite) {
                  return (
                  <Select.Option
                    key={entity.fieldRefEntite.entity.fieldAquaoOid}
                    value={entity.fieldRefEntite.entity.fieldAquaoOid}
                  >
                    {entity.fieldRefEntite.entity.title}
                  </Select.Option>
                )
              }
             })}
          </Select>
        </div>
      ) : (
        <>
          <div className="d-flex align-items-center mb-3">
            <p className="mb-0">
              Prestation 1 :
              {` ${firstComboBoxItem?.entity.fieldRefEntite.entity.title}`}
            </p>
          </div>
          <div className="d-flex align-items-center mb-3">
            <p className="mb-0">
              Prestation 2:
            </p>
            <Select
              size="large"
              placeholder="Veuillez sélectionner"
              onSelect={(value) => setSelectedOid(value)}
              dropdownAlign={{ offset: [0, 0] }}
              dropdownMatchSelectWidth={false}
              dropdownClassName="gift-modal--form--who--field--dropdown"
              className="gift-modal--form--who--field gift-modal--form--field ml-3"
            >
              {secondComboBoxItems.map(({ entity }) => (
                <Select.Option
                  key={entity.fieldRefEntite.entity.fieldAquaoOid}
                  value={entity.fieldRefEntite.entity.fieldAquaoOid}
                >
                  {entity.fieldRefEntite.entity.title}
                </Select.Option>
              ))}
            </Select>
          </div>
        </>
      )}
      <CaliceoButton
        title="Valider mon choix"
        onClick={() => confirmFirstStepChoice()}
        showIcon={false}
        primaryButton
      />
    </Modal>
  );
};

export default ChooseFromPackModal;
