export const firstImage = 'https://preprod.caliceo.com/sites/default/files/0fa0533eb698d7d5d59580d0b60c1ccd5f0ed07c.png';
export const secondImage = 'https://preprod.caliceo.com/sites/default/files/16143652_1382719541778381_3493684000679842453_o.jpg';
export const thirdImage = 'https://preprod.caliceo.com/sites/default/files/11174603_930722863644720_3048576865962374909_o.jpg';
export const fourthImage = 'https://preprod.caliceo.com/sites/default/files/25073266_1705569246160074_548529373647269893_o.jpg';
export const fifthImage = 'https://preprod.caliceo.com/sites/default/files/81578897_2868751056508548_8836946347400626176_o.jpg';
export const sixthImage = 'https://preprod.caliceo.com/sites/default/files/61243552_2420250458025279_2608905470806589440_o.jpg';
export const seventhImage = 'https://preprod.caliceo.com/sites/default/files/29249677_1803915152992149_6876768568231329792_o.jpg';
export const eightImage = 'https://preprod.caliceo.com/sites/default/files/29179000_1803914916325506_5367492132426743808_o.jpg';
export const ninthImage = 'https://preprod.caliceo.com/sites/default/files/11114006_930722993644707_1616877637122646380_o.jpg';
export const tenthImage = 'https://preprod.caliceo.com/sites/default/files/df8891adfacad2f099e3a683d1c19df254c618bb.png';
export const eleventhImage = 'https://preprod.caliceo.com/sites/default/files/61754770_2420662961317362_6107064567127343104_o.jpg';
export const twelvthImage = 'https://preprod.caliceo.com/sites/default/files/29214085_1803915032992161_3214757487475425280_o.jpg';
export const caliceoMainImage = 'https://preprod.caliceo.com/sites/default/files/18c73d1f88733053b73047f067dfc909fb457288.png';

export const dummyImageArray = [
  firstImage,
  secondImage,
  thirdImage,
  fourthImage,
  fifthImage,
  sixthImage,
  seventhImage,
  eightImage,
  ninthImage,
  tenthImage,
  eleventhImage,
  twelvthImage,
];
