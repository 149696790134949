import React, { useEffect, useCallback, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import queryString from 'query-string';
import PRODUCT_DETAILS_QUERY from 'hooks/use-offer-details';

import Layout from 'components/layout';
import SEO from 'components/seo';

import Reinsurance from 'components/home-group/reinsurance/Reinsurance';
import ProductHeader from './product-header/ProductHeader';
import SecondZone from './second-zone/SecondZone';
import ThirdZone from './third-zone/ThirdZone';
import FifthZone from './fifth-zone/FifthZone';
import ProductHero from './product-hero/ProductHero';
import FourthZone from './fourth-zone/FourthZone';
import SixthZone from './sixth-zone';
import SeventhZone from './seventh-zone/SeventhZone';
import EightZone from './eight-zone/EightZone';

import { useParams } from '@reach/router';

import { getCenterById } from 'utils/getCentersBy';

const ProductDetails = (props) => {
  const {
    location, centers, centerId, nid, alias,
  } = props;
  const [centerObject, setCenterObject] = useState({});
  const params = useParams();

  const [productDetailsConditions, setProductDetailsConditions] = useState([]);
  const [productAquaoOid, setProductAquaoOid] = useState('');

  useEffect(() => {
    if (centerId) {
      setCenterObject(getCenterById(centers, centerId));
    }
  }, [centerId, centers]);

  const getAquaoOid = () => {
    const searchString = location?.search;
    const parsedString = queryString.parse(searchString);
    setProductAquaoOid(parsedString ? parsedString.aquaoOid : '');
  };

  useEffect(() => {
    getAquaoOid();
  }, [location]);

  const [getProductDetails, productDetailsRes] = useLazyQuery(PRODUCT_DETAILS_QUERY);

  useEffect(() => {
    if (!productDetailsRes.data) {
      getProductDetails({
        variables: {
          productDetailsConditions,
        },
      });
    }
  }, [productDetailsConditions]);

  const createProductConditions = useCallback(
    () => {
      const productConditions = [
        { field: 'type', value: 'offre' },
        { field: 'nid', value: nid },
        (centerObject?.name ? { field: 'field_domain_access', value: `${centerObject.domainAccess}` } : []),
        // (productAquaoOid ? { field: 'field_aquao_oid', value: productAquaoOid } : []),
      ];
      return productConditions.flat();
    },
    [productAquaoOid, centerObject],
  );

  useEffect(() => {
    setProductDetailsConditions(createProductConditions());
  }, [productAquaoOid, centerObject, createProductConditions]);

  const productData = productDetailsRes.data?.nodeQuery.entities[0];
  if (!productDetailsRes.data) {
    return null;
  }

  if (productDetailsRes.loading) {
    return null;
  }

  const regex = /(<([^>]+)>)/ig;
  const result = productData?.fieldOffreZone2?.entity?.fieldZoneTexte?.processed.replace(regex, '').substr(0, 200);

  return (
    <Layout location={location}>
      <SEO title={productData.title + ' - ' + centerObject?.name} description={result} />
      <ProductHero
        universe={productData.fieldUnivers[0]?.entity}
        centerObject={centerObject}
        productId={nid}
        title={productData.title}
        alias={alias}
      />
      <ProductHeader
        title={productData.title}
        data={productData}
        customizeProductData={productData.fieldVariante}
        centerName={centerObject?.name}
        centerId={centerObject?.centerId}
        productAquaoOid={productAquaoOid}
      />
      <SecondZone data={productData.fieldOffreZone2} />
      <ThirdZone data={productData.fieldOffreZone3} />
      <FourthZone data={productData.fieldOffreZone4} />
      <SixthZone data={productData.fieldOffreZone6} />
      <SeventhZone data={productData.fieldOffreZone7} />
      <EightZone data={productData.fieldOffreZone8} />
      <FifthZone data={productData.fieldOffreZone5} />
      <Reinsurance location={location} />
    </Layout>
  );
};

export default ProductDetails;
