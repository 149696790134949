import React, { useState, useCallback, useEffect } from 'react';
import {
  Select, notification,
} from 'antd';
import qs from 'qs';
import { useLocation } from '@reach/router';
import { InputNumber } from 'antd';
import Cookies from 'universal-cookie';
import { useParams } from '@reach/router';

import API from 'api';

import CaliceoButton from 'components/buttons/CaliceoButton';

import AddToCartModal from '../customize-product/AddToCartModal/AddToCartModal';
import useDuoTooltip from 'hooks/use-duo-tooltip';
import { centerCookieIds } from 'utils/constants';

import './customizeVoucher.scss';

const { Option } = Select;

const cookies = new Cookies();

const CustomizeProduct = (props) => {
  const {
    data,
    actions: {
      setActiveVariant,
      setProductVariants,
      addLastItemUrl,
      removeLastItemUrl,
      setProductAsGift,
    },
    selectedVariant,
    centerName,
    productImage,
    productName,
    centerId,
  } = props;
  const location = useLocation();

  const [cartModalShown, setCartModalShown] = useState(false);
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [orderLoading, setOrderLoading] = useState(false);
  const [goToNextStepBooking, setGoToNextStepBooking] = useState(false);
  const [voucherValue, setVoucherValue] = useState(10);
  const quantityArray = [1, 2, 3, 4, 5];

  useEffect(() => {
    if (data.length) {
      setProductVariants(data);
      setActiveVariant(data[0].entity.nid);
    }
    removeLastItemUrl();
  }, []);

  const handleChangeQuantity = (val) => {
    setSelectedQuantity(val);
  };

  const addItemToCart = () => {
    setOrderLoading(true);
    addLastItemUrl(location.pathname);
    const itemData = {
      session: cookies.get(centerCookieIds[centerId].cookieName),
      quantity: selectedQuantity,
      variant: selectedVariant.entity.fieldAquaoOid,
      recipient: null,
      envp: null,
      datexp: null,
      mainpropre: false,
      email: null,
      gift: false
    };
    if(typeof window !== "undefined") {
      if(window.fbq != null) { 
        fbq('track', 'Purchase', {currency: "EUR", value: selectedVariant?.entity?.fieldPrix});
      }
    }
    setOrderLoading(true);
    return API.post('/ubeeupdbs', qs.stringify(itemData), {
      centerId,
    }).then((res) => {
      if (res.data.status === 1) {
        setOrderLoading(false);
        setCartModalShown(true);
      } else {
        notification.error({
          message: 'Une erreur s\'est produite. Veuillez réessayer.',
        });
        setOrderLoading(false);
      }
    });
  };

  const [itemLeft, setItemLeft] = useState(0);

  useEffect(() => {
    if (itemLeft > 0) {
      //console.log(itemLeft);
      async function addOneGiftItem() {
        const itemData = {
          session: cookies.get(centerCookieIds[centerId].cookieName),
          quantity: 1,
          variant: selectedVariant.entity.fieldAquaoOid,
          recipient: ' ',
          envp: null,
          datexp: null,
          mainpropre: false,
          email: null,
          gift:true
        };
        setOrderLoading(true);
        return API.post('/ubeeupdbs', qs.stringify(itemData), {
          centerId,
        }).then((res) => {
          if (res.data.status === 1) {
            if(itemLeft === 1) {
              setOrderLoading(false);
              setCartModalShown(true);
            }
            const [lastItem] = res.data.responseData.cartContents.Products.slice(-1);
            setProductAsGift(lastItem);
            setItemLeft(itemLeft-1);
          } else {
            notification.error({
              message: 'Une erreur s\'est produite. Veuillez réessayer.',
            });
            setOrderLoading(false);
          }
        });
      }

      addOneGiftItem();      
    }
  }, [itemLeft]);

  const addToCartAndGift = () => {
    setItemLeft(selectedQuantity);
  };

  function onChange(value) {
    const roundedVal = Math.round(value / 10) * 10;
    if (roundedVal <= 150 && roundedVal >= 10) {
      setVoucherValue(roundedVal);
    }
  }

  useEffect(() => {
    if (voucherValue) {
      const newVariant = data.find(
        ({ entity }) => parseInt(entity?.fieldPrix, 10) === voucherValue,
      );
      setActiveVariant(newVariant.entity.nid);
    }
  }, [voucherValue, data, setActiveVariant]);

  return (
    <div className="product-header--customization-widget">
      <div>
        <p className="custom-voucher-title">SAISIR VOTRE MONTANT</p>
        <InputNumber
          defaultValue={10}
          min={10}
          step={10}
          max={150}
          formatter={(value) => `${value}€`}
          value={voucherValue}
          onChange={onChange}
          className="custom-voucher-input"
        />
        <div className="voucher-prices--wrapper">
          <div
            onClick={() => setVoucherValue(10)}
            role="presentation"
            className="voucher-price"
          >
            10€
          </div>
          <div
            onClick={() => setVoucherValue(20)}
            role="presentation"
            className="voucher-price"
          >
            20€
          </div>
          <div
            onClick={() => setVoucherValue(50)}
            role="presentation"
            className="voucher-price"
          >
            50€
          </div>
          <div
            onClick={() => setVoucherValue(100)}
            role="presentation"
            className="voucher-price"
          >
            100€
          </div>
        </div>

        <div className="product--select--wrapper">
          <span className="product--select--label">Quantité : </span>
          <Select
            onChange={handleChangeQuantity}
            dropdownAlign={{ offset: [0, 0] }}
            value={selectedQuantity}
            className="product--select"
          >
            {quantityArray.map((quantity) => (
              <Option value={quantity} key={quantity}>
                {quantity}
              </Option>
            ))}
          </Select>
        </div>

      </div>
      <hr className="product-widget--divider" />
      <p className="product-widget--text">
        Prestation valable uniquement dans le centre de
        {' '}
        {centerName}
      </p>
      {/* <div className="product-variant--prices">
        <span className={`${selectedVariant?.entity.fieldPrixPromo ? 'has-promo' : ''} product-variant-price`}>
          {`${getRealProductPrice(selectedVariant?.entity.fieldPrix, selectedQuantity)}€`}
        </span>
        {selectedVariant?.entity.fieldPrixPromo
          && <span className="product-variant-price">{`${getRealProductPrice(selectedVariant?.entity.fieldPrixPromo, selectedQuantity)}€`}</span>}
      </div> */}
      <CaliceoButton
        primaryButton
        title="Acheter pour soi"
        classNames="product-widget-button"
        onClick={addItemToCart}
        loading={orderLoading}
        disabled={orderLoading}
      />
      <CaliceoButton
        primaryButton={false}
        title="Offrir ce produit"
        iconFill="#8bacdb"
        classNames="primary-caliceo--button--inverted product-widget-button"
        onClick={addToCartAndGift}
      />
      
      <AddToCartModal
        cartModalShown={cartModalShown}
        setCartModalShown={setCartModalShown}
        selectedVariant={selectedVariant}
        selectedQuantity={selectedQuantity}
        centerName={centerName}
        productImage={productImage}
        productName={productName}
        centerId={centerId}
        setShowFirstStepBooking={() => {}}
        setGoToNextStepBooking={setGoToNextStepBooking}
      />
    </div>
  );
};

export default CustomizeProduct;
