import React from 'react';
import PropTypes from 'prop-types';

import CaliceoLogo from 'images/caliceo-logo-products.png'

import CustomizeProduct from './customize-product';
import CustomizeVoucher from './customize-voucher';
import './productHeader.scss';

const ProductHeader = (props) => {
  const {
    data,
    title,
    customizeProductData,
    centerName,
    centerId,
    productAquaoOid,
  } = props;

  return (
    <div>
      <h1 className="product-header--title d-block d-md-none">{title}</h1>
      <div className="product-header--wrapper">
        <img src={data?.fieldOffreZone1?.entity?.fieldZoneImage?.entity?.fieldMediaImage?.url} className="product-header--image" alt={`Center Lyon - ${title}`} />
        <h1 className="product-header--title d-none d-md-block">{title}</h1>
        <img src={CaliceoLogo} className="product-header--caliceo-logo" alt="Caliceo Logo" />
      </div>
      <div className="container">
        <div className="product-header-content--wrapper">
          <div className="product-header--description" dangerouslySetInnerHTML={{ __html: data?.fieldOffreZone1?.entity?.fieldZoneTexte?.processed }} />
          <div className="product-header--customatization-widget--wrapper">
            {title.includes('Chèque') ? (
              <CustomizeVoucher
                centerName={centerName}
                data={customizeProductData}
                productName={title}
                centerId={centerId}
                productImage={
                  data?.fieldOffreZone1?.entity?.fieldZoneImage?.entity?.fieldMediaImage?.url
                }
              />
            ) : (
              <CustomizeProduct
                centerName={centerName}
                data={customizeProductData}
                productName={title}
                centerId={centerId}
                productAquaoOid={productAquaoOid}
                productImage={
                  data?.fieldOffreZone1?.entity?.fieldZoneImage?.entity?.fieldMediaImage?.url
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ProductHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ProductHeader;
