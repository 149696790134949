import React, { useState, useEffect } from 'react';
import { Tabs, Collapse } from 'antd';
import { UpCircleFilled, PlusCircleFilled } from '@ant-design/icons';

import useWindowWidth from 'hooks/use-window-width';
import './fifthZone.scss';

const { TabPane } = Tabs;
const { Panel } = Collapse;

const FifthZone = (props) => {
  const { data } = props;

  const [windowWidth, setWindowWidth] = useState(0);
  const [activeTab, setActiveTab] = useState('');

  useWindowWidth({ setWindowWidth });

  useEffect(() => {
    setActiveTab(data?.entity?.fieldZoneOnglet[0]?.entity.fieldOngletTitre);
  }, []);

  const changeActiveTab = (tabKey) => {
    setActiveTab(tabKey);
  };

  const getImageForActiveTab = (currentActiveTab, tabs) => {
    if (!currentActiveTab) return null;
    const active = tabs.find((tab) => tab.entity?.fieldOngletTitre === currentActiveTab);
    return (
      <div className="fifth-zone--image--wrapper">
        <img src={active?.entity.fieldOngletImage?.entity.fieldMediaImage.url} className="fifth-zone--image" alt={active.entity.fieldOngletTitre} />
      </div>
    );
  };

  const handleExpandIcon = (i) => {
    if (i.isActive) {
      return <UpCircleFilled className="fourth-zone--accordion-icon" />;
    }
    return <PlusCircleFilled className="fourth-zone--accordion-icon" />;
  };

  if (!data?.entity.fieldZoneOnglet.length) {
    return null;
  }


  return (
    <div className="container">
      <div className="fifth-zone-content--wrapper">
        {windowWidth > 768 ? (
          <>
            <Tabs size="small" onChange={changeActiveTab} className={`${data.entity.fieldZoneOnglet > 3 ? 'full-width-tabs' : 'half-width-tabs'} fifth-zone--tabs`}>
              {data?.entity.fieldZoneOnglet.map(({ entity }) => (
                <TabPane tab={entity.fieldOngletTitre} key={entity.fieldOngletTitre}>
                  <div className="fifth-zone--content" dangerouslySetInnerHTML={{ __html: entity?.fieldOngletTexte?.processed }} />
                </TabPane>
              ))}
            </Tabs>
            {getImageForActiveTab(activeTab, data?.entity.fieldZoneOnglet)}
          </>
        ) : (
          <Collapse expandIcon={(i) => handleExpandIcon(i)} expandIconPosition="right" bordered={false} accordion className="fourth-zone--accordions">
            {data?.entity.fieldZoneOnglet.map(({ entity }, i) => (
              <Panel header={`${i + 1}.  ${entity.fieldOngletTitre}`} key={entity.fieldOngletTitre}>
                <div dangerouslySetInnerHTML={{ __html: entity?.fieldOngletTexte?.processed }} />
              </Panel>
            ))}
          </Collapse>
        )}

      </div>
    </div>
  );
};

export default FifthZone;
