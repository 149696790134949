const timeConvert = (duration) => {
  const num = duration;
  const hours = num / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  return (
    `${rhours > 0 ? `${rhours} heure(s)` : ''} ${rminutes > 0 ? `${rminutes} minute(s)` : ''}`
  );
};



export default timeConvert;
