import React from 'react';
import { Modal } from 'antd';
import { navigate } from 'gatsby';
// dummy image
import { fourthImage } from 'utils/dummyImages';

import CaliceoButton from 'components/buttons/CaliceoButton';
import timeConvert from 'utils/time';
import './addToCartModal.scss';

const AddToCartModal = (props) => {
  const {
    cartModalShown,
    setCartModalShown,
    selectedVariant,
    selectedQuantity,
    centerName,
    productImage,
    productName,
    centerId,
    setShowFirstStepBooking,
    setGoToNextStepBooking,
  } = props;

  if (!cartModalShown) {
    return null;
  }

  const getOfferPrice = (quantity, price) => {
    //console.log(price, Number.isInteger(price));
    if (Number.isInteger(price)) {
      return quantity * price;
    }
    return parseFloat(quantity * price).toFixed(2);
  };

  const cancelCartModal = () => {
    setCartModalShown(false);
    setShowFirstStepBooking(false);
    setGoToNextStepBooking(false);
  };

  return (
    <Modal
      className="one-time-modal add-to-cart-modal"
      visible={cartModalShown}
      footer={null}
      width="auto"
      onCancel={cancelCartModal}
    >
      <h3 className="one-time-modal--title">
        Votre produit a bien été
        <br />
        ajouté à votre panier
      </h3>
      <p>
        Valable uniquement dans le centre de
        {' '}
        {centerName}
        <br />
        {((selectedVariant.entity?.fieldAquaoOid != '193') && (selectedVariant.entity?.fieldAquaoOid != '189') && (selectedVariant.entity?.fieldAquaoOid != '422') && (selectedVariant.entity?.fieldAquaoOid != '743') && (selectedVariant.entity?.fieldAquaoOid != '406') && (selectedVariant.entity?.fieldAquaoOid != '744')) ? (
            <>
            {selectedVariant.entity.fieldDureeValidite === 365 ? "" : `${selectedVariant.entity.fieldDureeValidite}journées à compter de la date d-achat.`} 
            </>
        ) : ( " " )}
        
      </p>
      <div className="add-cart-modal--body">
        <div className="add-cart-modal--info--wrapper">
          <div className="add-cart-modal--image--wrapper">
            <img src={productImage || fourthImage} alt={productName} />
          </div>
          <div className="add-cart-modal--info">
            <h4 className="add-cart-modal--product-title">{productName}</h4>
            {selectedVariant.entity.fieldNombreSeance === 1 ? (
              <>
                {selectedVariant.entity?.fieldConsommablesList[0]?.entity.fieldRefEntite?.entity?.fieldDureePrestation !== '0' ? (
                  <>
                    {((selectedVariant.entity?.fieldAquaoOid != '430') && (selectedVariant.entity?.fieldAquaoOid != '431') && (selectedVariant.entity?.fieldAquaoOid != '573') && (selectedVariant.entity?.fieldAquaoOid != '340') && (selectedVariant.entity?.fieldAquaoOid != '648') && (selectedVariant.entity?.fieldAquaoOid != '647')) ? (
                      <>
                      {/*(selectedVariant.entity?.fieldConsommablesList[0]?.entity.fieldRefEntite?.entity?.fieldDureePrestation) ? (
                        <p className="add-cart-modal--info-details">
                          Durée :
                          {' '}
                          {timeConvert(selectedVariant.entity?.fieldConsommablesList[0]?.entity.fieldRefEntite?.entity?.fieldDureePrestation)}
                        </p>
                      ) : ( " " )*/}  
                      </>
                  ) : ( " " )}
                  </>               
                ) : (
                  <p className="add-cart-modal--info-details">
                    Séance :
                    {' '}
                    {selectedVariant?.entity.fieldNombreSeance}
                  </p>
                )}
              </>
            ) : (
              <p className="add-cart-modal--info-details">
                Séances :
                {' '}
                {selectedVariant?.entity.fieldNombreSeance}
              </p>
            )}

            <p className="add-cart-modal--info-details">
              Quantité :
              {' '}
              {selectedQuantity}
            </p>
          </div>
        </div>
        <div className="add-cart-modal--buttons">
          <CaliceoButton
            primaryButton
            onClick={() => navigate('/panier')}
            title="Voir mon panier"
          />
          <CaliceoButton
            primaryButton={false}
            onClick={cancelCartModal}
            title="Continuer mes achats"
            classNames="secondary-caliceo--button mt-3"
          />
        </div>
      </div>
      <p className="add-cart-modal--price">
        {/* {getOfferPrice(selectedQuantity, selectedVariant.entity.fieldPrixPromo || selectedVariant.entity.fieldPrix)} */}
        {getOfferPrice(selectedQuantity, selectedVariant.entity.fieldPrix)}
        € TTC
      </p>
    </Modal>
  );
};

export default AddToCartModal;
