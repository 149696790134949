import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  setActiveVariant,
  setProductVariants,
  changeSelectedVariant,
} from 'redux/actions/customizeProduct';

import {
  addLastItemUrl,
  removeLastItemUrl,
  setProductAsGift,
} from 'redux/actions/cart';

import { selectProductVariants, selectActiveVariant, selectVariant } from 'redux/selectors/customizeProduct';

const mapStateToProps = (state) => ({
  productVariants: selectProductVariants(state),
  activeVariant: selectActiveVariant(state),
  selectedVariant: selectVariant(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setActiveVariant,
    setProductVariants,
    changeSelectedVariant,
    addLastItemUrl,
    removeLastItemUrl,
    setProductAsGift,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
