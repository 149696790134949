import React, { useState, useCallback, useEffect } from 'react';
import {
  Select, Checkbox, Popover, notification,
} from 'antd';
import qs from 'qs';
import moment from 'moment';
import Cookies from 'universal-cookie';
import { useParams } from '@reach/router';
import { navigate } from 'gatsby';

import API from 'api';

import CaliceoButton from 'components/buttons/CaliceoButton';
import InfoIcon from 'images/icons/info-icon.svg';
import SpaBookingModal from 'components/spa-booking/SpaBookingModal';

import BookingCompletedModal from 'components/booking-completed/BookingCompletedModal';
import ReserveTimeModal from 'components/reserve-time-modal/ReserveTimeModal';
import ChooseFromPackModal from 'components/spa-booking/ChooseFromPackModal';
import NoBookingModal from 'components/cancellation-modal/NoBookingModal';

import useDuoTooltip from 'hooks/use-duo-tooltip';
import { centerCookieIds } from 'utils/constants';
import AddToCartModal from './AddToCartModal/AddToCartModal';
import { useLocation } from '@reach/router';
const { Option } = Select;



const cookies = new Cookies();

const CustomizeProduct = (props) => {
  const {
    data,
    actions: {
      setActiveVariant,
      setProductVariants,
      addLastItemUrl,
      removeLastItemUrl,
      setProductAsGift,
    },
    productVariants,
    selectedVariant,
    centerName,
    productImage,
    productName,
    centerId,
    productAquaoOid,
  } = props;
  const location = useLocation();

  const { tooltipContent } = useDuoTooltip();
  const [cartModalShown, setCartModalShown] = useState(false);
  const [showSpaCalendar, setShowSpaCalendar] = useState(false);
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [hideDurationDropdown, setHideDurationDropdown] = useState(false);
  const [showFirstStepBooking, setShowFirstStepBooking] = useState(false);
  const [bookingModalShown, setBookingModalShown] = useState(false);
  const [isTimeType, setIsTimeType] = useState(false);
  const [duo, setDuo] = useState(false);
  const [orderLoading, setOrderLoading] = useState(false);
  const [spaBookingData, setSpaBookingData] = useState([]);
  const [selectedOid, setSelectedOid] = useState('');
  const [goToNextStepBooking, setGoToNextStepBooking] = useState(false);
  const [selectedConsumable, setSelectedConsumable] = useState(null);
  const [showBookingCompleted, setShowBookingCompleted] = useState(false);
  const [showReserveTimeModal, setShowReserveTimeModal] = useState(false);
  const [showNoBookingModal, setShowNoBookingModal] = useState(false);
  const [selectedFromFirstStep, setSelectedFromFirstStep] = useState(false);

  const [newAquaoid, setNewAquaoid] = useState(0);

  const quantityArray = [1, 2, 3, 4];

  const comboBoxOids = [
    '340', '573', '133',
  ];

  //console.log(selectedVariant);

  useEffect(() => {
    if (data.length) {
      setProductVariants(data);
      
      
      if (productAquaoOid) {
        const fromDetails = data.find((item) => item.entity?.fieldAquaoOid === productAquaoOid);
        if(fromDetails) {
          setActiveVariant(fromDetails.entity?.nid);
          setSelectedConsumable(fromDetails.entity?.fieldConsommablesList[0]);
        }else {
          setActiveVariant(data[0].entity?.nid);
          setSelectedConsumable(data[0].entity?.fieldConsommablesList[0]);
        }
        
      } else {
        setActiveVariant(data[0].entity?.nid);
        setSelectedConsumable(data[0].entity?.fieldConsommablesList[0]);
      }
    }
    removeLastItemUrl();
    
    
    
    if(cookies.get('duo') === "ok") {
      if(data[0].entity?.fieldDuo) {
        setDuo(true);
      }

    }
  }, []);

  const handleChangeVariant = (val) => {
    setActiveVariant(val);
    data.map((entity) => {
      if(val == entity?.entity?.nid) {
        setNewAquaoid(entity?.entity?.fieldConsommablesList[0].entity?.fieldRefEntite.entity?.fieldAquaoOid);
        setSelectedConsumable(entity?.entity?.fieldConsommablesList[0]);
      }
    })
  };

  const handleCheckboxChange = (val) => {
    if(data[0].entity?.fieldDuo) {
      setDuo(val.target.checked);
      if(val.target.checked) {
        cookies.set('duo', 'ok', { path: '/' });
        setSelectedQuantity(1);
      }else {
        cookies.set('duo', '', { path: '/' });
      }
    }
  };

  const handleChangeQuantity = (val) => {
    setSelectedQuantity(val);
  };

  const getRealProductPrice = useCallback(
    (productPrice, productQuantity) => {
      const priceNum = parseFloat(productPrice);
      if(data[0].entity?.fieldDuo) {
        if(cookies.get('duo') === "ok") {
          productQuantity += 1;
        }
      }
      
      return priceNum * productQuantity;
    },
    [],
  );

  const tooltipProcessedContent = (
    <div dangerouslySetInnerHTML={{ __html: tooltipContent }} />
  );

  useEffect(() => {
    const checkIfTime = () => data.every(({ entity }) => entity?.fieldNombreSeance === 1);
    setIsTimeType(checkIfTime());
  }, []);

  const formatTimeType = (time) => {
    const timeNumber = parseInt(time, 10);
    if (timeNumber <= 60) {
      return `${timeNumber} min`;
    }
    return `${(timeNumber / 60)} heures`;
  };

  const startAddingItemToCart = () => {
    const windowUrl = window.location.search;
    //const params = new URLSearchParams(windowUrl);
    const id_pr = windowUrl.split('=');

    if(typeof window !== "undefined") {
      if(window.fbq != null) { 
        fbq('track', 'Purchase', {currency: "EUR", value: selectedVariant?.entity?.fieldPrix});
      }
    }

    if(id_pr[1] == 743 || id_pr[1] == 744 || id_pr[1] == 406 || id_pr[1] == 422) {
      addDirectlyToCart();
    }else {
      if(selectedQuantity > 2) {
        addDirectlyToCart();
      }else {
        // if (
        //   (selectedConsumable.entity?.fieldRefEntite?.entity?.fieldAquaoBranchActivite === 'SPA' && selectedVariant?.entity?.fieldConsommablesList.length > 1)
        //   || (selectedConsumable.entity?.fieldRefEntite?.entity?.fieldAquaoBranchActivite === 'SPA' && selectedVariant?.entity?.fieldNombreSeance <= 1)
        // ) {
        if (
          (selectedConsumable.entity?.fieldRefEntite?.entity?.fieldAquaoBranchActivite === 'SPA' && selectedVariant?.entity?.fieldConsommablesList.length > 1)
          || (selectedConsumable.entity?.fieldRefEntite?.entity?.fieldAquaoBranchActivite === 'SPA' && selectedVariant?.entity?.fieldNombreSeance <= 1)
        ) {
          
          if(selectedVariant?.entity?.fieldNombreSeance > 2) {
            addDirectlyToCart();
          }else {
            // Ici si il faut faire les 2 modal de suite.
            
            if(selectedQuantity === 2) {
              cookies.set('qte', '2', { path: '/' });
            }
            setShowReserveTimeModal(true);
          }
        } else {
          setGoToNextStepBooking(true);
        }
      }
    }
    
    
  };

  useEffect(() => {
    if (selectedOid) {
      const findConsumableByOid = selectedVariant?.entity?.fieldConsommablesList.find(
        ({ entity }) => entity?.fieldRefEntite.entity?.fieldAquaoOid === selectedOid,
      );
      setSelectedConsumable(findConsumableByOid);
    }
  }, [selectedOid, selectedVariant]);

  const addItemToCart = () => {

    var spaData = {};
    const tommorow = moment().add(1, 'days').format('DD/MM/YYYY');
    if(newAquaoid !== 0) {
      spaData = {
        session: cookies.get(centerCookieIds[centerId]?.cookieName),
        bid: 's',
        cid: centerCookieIds[centerId]?.cid,
        pid: newAquaoid,
        tid: 1,
        date: tommorow,
        recipient: null,
      };
    }else {
      spaData = {
        session: cookies.get(centerCookieIds[centerId]?.cookieName),
        bid: 's',
        cid: centerCookieIds[centerId]?.cid,
        pid: selectedOid
          || selectedVariant?.entity?.fieldConsommablesList[0]?.entity
            .fieldRefEntite?.entity?.fieldAquaoOid,
        tid: 1,
        date: tommorow,
        recipient: null,
      };
    }
    

    const qstring = Object.keys(spaData).map((key) => `${key}=${spaData[key]}`).join('&');
    setOrderLoading(true);
    addLastItemUrl(location.pathname);
    if (selectedFromFirstStep || (selectedConsumable.entity?.fieldRefEntite?.entity?.fieldAquaoBranchActivite === 'SPA' && selectedVariant?.entity?.fieldConsommablesList.length <= 1 && selectedVariant?.entity?.fieldNombreSeance <= 1)) {
      return API.get(`/fctfbot?${qstring}`, { centerId }).then((res) => {
        if (res.data.status === 1) {
          setSpaBookingData(res.data.responseData.bookingContent);
          setOrderLoading(false);
          setShowSpaCalendar(true);
          setSelectedFromFirstStep(false);
        }else if (res.data.status === 5) {
          navigate('/login');
        }else {
          /*notification.error({
            message: 'Une erreur s\'est produite. Veuillez réessayer.',
          });*/
          setSpaBookingData();
          setShowNoBookingModal(true);
          setOrderLoading(false);
        }
      });
    }

    const itemData = {
      session: cookies.get(centerCookieIds[centerId].cookieName),
      quantity: selectedQuantity,
      variant: selectedVariant.entity?.fieldAquaoOid,
      recipient: null,
      envp: null,
      datexp: null,
      mainpropre: false,
      email: null,
      gift:false
    };
    setOrderLoading(true);
    return API.post('/ubeeupdbs', qs.stringify(itemData), {
      centerId,
    }).then((res) => {
      if (res.data.status === 1) {
        setOrderLoading(false);
        setCartModalShown(true);
      } else {
        notification.error({
          message: 'Une erreur s\'est produite. Veuillez réessayer.',
        });
        setOrderLoading(false);
      }
    });
  };

  const addDirectlyToCart = () => {
    const itemData = {
      session: cookies.get(centerCookieIds[centerId].cookieName),
      quantity: selectedQuantity,
      variant: selectedVariant.entity?.fieldAquaoOid,
      recipient: null,
      envp: null,
      datexp: null,
      mainpropre: false,
      email: null,
      gift:false
    };
    setOrderLoading(true);
    return API.post('/ubeeupdbs', qs.stringify(itemData), {
      centerId,
    }).then((res) => {
      if (res.data.status === 1) {
        setOrderLoading(false);
        setCartModalShown(true);
      } else {
        notification.error({
          message: 'Une erreur s\'est produite. Veuillez réessayer.',
        });
        setOrderLoading(false);
      }
    });
  };

  useEffect(() => {
    if (goToNextStepBooking) {
      addItemToCart();
    }
  }, [goToNextStepBooking]);

  const [itemLeft, setItemLeft] = useState(0);

  useEffect(() => {
    if (itemLeft > 0) {
      //console.log(itemLeft);
      async function addOneGiftItem() {
        const itemData = {
          session: cookies.get(centerCookieIds[centerId].cookieName),
          quantity: 1,
          variant: selectedVariant.entity?.fieldAquaoOid,
          recipient: ' ',
          envp: null,
          datexp: null,
          mainpropre: false,
          email: null,
          gift:true
        };
        setOrderLoading(true);
        return API.post('/ubeeupdbs', qs.stringify(itemData), {
          centerId,
        }).then((res) => {
          if (res.data.status === 1) {
            if(itemLeft === 1) {
              setOrderLoading(false);
              setCartModalShown(true);
            }
            const [lastItem] = res.data.responseData.cartContents.Products.slice(-1);
            setProductAsGift(lastItem);
            setItemLeft(itemLeft-1);
          } else {
            notification.error({
              message: 'Une erreur s\'est produite. Veuillez réessayer.',
            });
            setOrderLoading(false);
          }
        });
      }

      addOneGiftItem();      
    }
  }, [itemLeft]);

  const addToCartAndGift = () => {
    setItemLeft(selectedQuantity);
  };

  return (
    <div className="product-header--customization-widget">
      {(data.length > 1 && data[0].entity?.fieldNombreSeance) && (
        <div className="product--select--wrapper">
          <span className="product--select--label">
            {isTimeType ? 'Durée :' : 'Séance :'}
          </span>
          <Select
            onChange={handleChangeVariant}
            dropdownAlign={{ offset: [0, 0] }}
            value={
              isTimeType ? (
                formatTimeType(
                  selectedVariant?.entity
                    .fieldConsommablesList[0]?.entity?.fieldRefEntite?.entity?.fieldDureePrestation,
                )
              ) : (
                  `${selectedVariant?.entity?.fieldNombreSeance === 1 ? `${selectedVariant?.entity?.fieldNombreSeance} séance` : `${selectedVariant?.entity?.fieldNombreSeance} séances `}`
              )
            }
            className="product--select"
          >
            {data.map(({ entity }) => (
              <Option value={entity?.nid} key={entity?.nid}>
                {isTimeType ? formatTimeType(entity?.fieldConsommablesList[0].entity?.fieldRefEntite?.entity?.fieldDureePrestation) : `${entity?.fieldNombreSeance === 1 ? `${entity?.fieldNombreSeance} séance` : `${entity?.fieldNombreSeance} séances `}`}
              </Option>
            ))}
          </Select>
        </div>
      )}
      {selectedVariant?.entity?.fieldDuo
        && (
          <div className="product--select--wrapper">
            <Checkbox onChange={handleCheckboxChange} checked={duo}>
              En duo
              <Popover content={tooltipProcessedContent} placement="right" trigger="hover">
                <img src={InfoIcon} alt="More info" />
              </Popover>
            </Checkbox>
          </div>
        )}
      {/* {selectedVariant?.entity?.fieldConsommablesList[0]?.entity?.fieldRefEntite?.entity?.fieldAquaoBranchActivite === 'SPA' && ( */}
        <div className="product--select--wrapper">
          <span className="product--select--label">Quantité : </span>
          <Select
            onChange={handleChangeQuantity}
            dropdownAlign={{ offset: [0, 0] }}
            value={selectedQuantity}
            className="product--select"
          >
            {quantityArray.map((quantity) => (
              <Option value={quantity} key={quantity}>
                {quantity}
              </Option>
            ))}
          </Select>
        </div>
      {/* )} */}
      <p className="product-widget--text">
        Prestation valable uniquement dans le centre de
        {' '}
        {centerName}
      </p>
      <hr className="product-widget--divider" />
      <div className="product-variant--prices">
        {selectedVariant?.entity?.fieldPrixPromo && (<span className={`${selectedVariant?.entity?.fieldPrixPromo ? 'has-promo' : ''} product-variant-price`}>
          {`${getRealProductPrice(selectedVariant?.entity?.fieldPrixPromo, selectedQuantity)}€`}
        </span>)}
        {selectedVariant?.entity?.fieldPrix
          && <span className="product-variant-price">{`${getRealProductPrice(selectedVariant?.entity?.fieldPrix, selectedQuantity).toFixed(2)}€`}</span>}
      </div>
      <CaliceoButton
        primaryButton
        title="Acheter pour soi"
        classNames="product-widget-button"
        onClick={startAddingItemToCart}
        loading={orderLoading}
        disabled={orderLoading}
      />
      
      <CaliceoButton
        primaryButton={false}
        title="Offrir ce produit"
        iconFill="#8bacdb"
        classNames="primary-caliceo--button--inverted product-widget-button"
        onClick={addToCartAndGift}
      />
      
      <AddToCartModal
        cartModalShown={cartModalShown}
        setCartModalShown={setCartModalShown}
        selectedVariant={selectedVariant}
        selectedQuantity={selectedQuantity}
        centerName={centerName}
        productImage={productImage}
        productName={productName}
        centerId={centerId}
        setShowFirstStepBooking={setShowFirstStepBooking}
        setGoToNextStepBooking={setGoToNextStepBooking}
      />
      {showSpaCalendar && (
        <SpaBookingModal
          spaBookingData={spaBookingData}
          setSpaBookingData={setSpaBookingData}
          setShowSpaCalendar={setShowSpaCalendar}
          showSpaCalendar={showSpaCalendar}
          offer={selectedVariant}
          selectedOid={selectedOid}
          quantity={selectedQuantity}
          variant={selectedVariant?.entity?.fieldAquaoOid}
          centerId={centerId}
          setOrderLoading={setOrderLoading}
          setCartModalShown={setCartModalShown}
          setGoToNextStepBooking={setGoToNextStepBooking}
          setShowFirstStepBooking={setShowFirstStepBooking}
          selectedConsumable={selectedConsumable}
          setShowBookingCompleted={setShowBookingCompleted}
          isAccountPage={false}
          sessionOverride={""}
        />
      )}
      {showBookingCompleted && (
        <BookingCompletedModal
          selectedVariant={selectedConsumable}
          productType={selectedConsumable.entity?.fieldRefEntite?.entity?.fieldAquaoBranchActivite}
          setShowBookingCompleted={setShowBookingCompleted}
          showBookingCompleted={showBookingCompleted}
          isAccountPage={false}
          centerId={centerId}
        />
      )}
      {showReserveTimeModal && (
        <ReserveTimeModal
          setShowReserveTimeModal={setShowReserveTimeModal}
          showReserveTimeModal={showReserveTimeModal}
          setGoToNextStepBooking={setGoToNextStepBooking}
          setShowFirstStepBooking={setShowFirstStepBooking}
          addDirectlyToCart={addDirectlyToCart}
        />
      )}
      {showFirstStepBooking && (
        <ChooseFromPackModal
          showFirstStepBooking={showFirstStepBooking}
          setShowFirstStepBooking={setShowFirstStepBooking}
          setShowSpaCalendar={setShowSpaCalendar}
          offer={selectedVariant}
          setSelectedOid={setSelectedOid}
          selectedOid={selectedOid}
          handleChangeVariant={handleChangeVariant}
          setGoToNextStepBooking={setGoToNextStepBooking}
          productName={productName}
          setSelectedFromFirstStep={setSelectedFromFirstStep}
        />
      )}
      {showNoBookingModal && (
        <NoBookingModal
          showNoBookingModal={showNoBookingModal}
          setShowNoBookingModal={setShowNoBookingModal}
          
        />
      )}
    </div>
  );
};

export default CustomizeProduct;
