import React from 'react';

import './secondZone.scss';

const SecondZone = (props) => {
  const { data } = props;

  if (!data?.entity.fieldZoneTitre) {
    return null;
  }

  return (
    <div className="container">
      <div className="second-zone--wrapper">
        <div className="second-zone--image--wrapper">
          <img src={data.entity?.fieldZoneImage?.entity?.fieldMediaImage.url} alt={data.entity.fieldZoneTitre} className="second-zone--image"/>
        </div>
        <div className="second-zone--content--wrapper">
          <h2 className="second-zone--header">{data.entity.fieldZoneTitre}</h2>
          <div className="second-zone--desc" dangerouslySetInnerHTML={{ __html: data.entity?.fieldZoneTexte?.processed }} />
        </div>
      </div>
    </div>
  );
};

export default SecondZone;
