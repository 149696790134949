import gql from 'graphql-tag';

const PRODUCT_DETAILS_QUERY = gql `
  query ProductDetailsQuery($productDetailsConditions: [EntityQueryFilterConditionInput]) {
    nodeQuery(
      filter: {
        conditions: $productDetailsConditions
      }
    ) {
      entities {
        ... on NodeOffre {
          status
          title
          nid
          path {
            alias
          }
          fieldUnivers {
            entity {
              tid
              name
            }
          }
          fieldAquaoOid
          fieldDomainAccess {
            entity {
              entityId
            }
          }
          fieldVariante {
            entity {
              ... on NodeVariante {
                fieldAquaoOid
                fieldCode
                fieldDuo
                fieldPrix
                fieldPrixPromo
                fieldAddselling {
                  entity {
                    ...on ParagraphAddsellingVariante {
                      fieldTexte {
                        processed
                      }
                      fieldVariante {
                        entity {
                          ...on NodeVariante {
                            fieldAquaoOid
                          }
                        }
                      }
                    }
                  }
                }
                fieldUpselling {
                  entity {
                    ...on ParagraphAddsellingVariante {
                      fieldTexte {
                        processed
                      }
                      fieldVariante {
                        entity {
                          ...on NodeVariante {
                            fieldAquaoOid
                          }
                        }
                      }
                    }
                  }
                }
                fieldVarianteType
                fieldDureeValidite
                fieldPromoTemporaire
                fieldNombreSeance
                nid
                fieldDateFinVente {
                  date
                }
                fieldDatePublication {
                  date
                }
                fieldConsommablesList {
                  entity {
                    ... on ParagraphConsommable {
                      fieldAffichageFront
                      fieldUtilisationSelection
                      fieldRefEntite {
                        entity {
                          ... on NodeConsommable {
                            title
                            fieldAquaoOid
                            fieldDescription {
                              processed
                            }
                            fieldImage {
                              entity {
                                ... on MediaImage {
                                  fieldMediaImage {
                                    title
                                    url
                                  }
                                }
                              }
                            }
                            fieldDureePrestation
                            fieldPlanifiable
                            fieldDeuxiemeDescription {
                              processed
                            }
                            fieldAquaoTrainingOid
                            fieldAquaoBranchActivite
                            fieldCombobox
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          fieldOffreZone1 {
            entity {
              ... on ParagraphZone1 {
                fieldZoneTexte {
                  processed
                }
                fieldZoneImage {
                  entity {
                    ... on MediaImage {
                      fieldMediaImage {
                        title
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          fieldOffreZone2 {
            entity {
              ... on ParagraphZone2 {
                fieldZoneTitre
                fieldZoneTexte {
                  processed
                }
                fieldZoneImage {
                  entity {
                    ... on MediaImage {
                      fieldMediaImage {
                        title
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          fieldOffreZone3 {
            entity {
              ... on ParagraphZone3 {
                fieldZoneTitre
                fieldZoneTexte {
                  processed
                }
                fieldZoneDeuxiemeTexte {
                  processed
                }
                fieldZoneIcone {
                  entity {
                    ... on MediaImage {
                      fieldMediaImage {
                        title
                        url
                      }
                    }
                  }
                }
                fieldZoneImage {
                  entity {
                    ... on MediaImage {
                      fieldMediaImage {
                        title
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          fieldOffreZone4 {
            entity {
              ... on ParagraphZone4 {
                fieldZoneTitre
                fieldZoneSousTitre {
                  processed
                }
                fieldZoneAccroche {
                  processed
                }
                fieldZoneImage {
                  entity {
                    ... on MediaImage {
                      fieldMediaImage {
                        title
                        url
                      }
                    }
                  }
                }
                fieldZoneAccordeon {
                  entity {
                    ... on ParagraphOffreAccordeon {
                      fieldAccordeonTitre
                      fieldAccordeonText {
                        processed
                      }
                    }
                  }
                }
              }
            }
          }
          fieldOffreZone5 {
            entity {
              ... on ParagraphZone5 {
                fieldZoneOnglet {
                  entity {
                    ... on ParagraphOffreOnglet {
                      fieldOngletTitre
                      fieldOngletTexte {
                        processed
                      }
                      fieldOngletImage {
                        entity {
                          ... on MediaImage {
                            fieldMediaImage {
                              title
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          fieldOffreZone6 {
            entity {
              ... on ParagraphZone6 {
                fieldZoneTitre
              }
            }
          }
          fieldOffreZone7 {
            entity {
              ... on ParagraphZone7 {
                fieldZoneTitre
                fieldZoneLegende {
                  entity {
                    ... on ParagraphOffreLegende {
                      fieldLegendeTitre
                      fieldLegendeCouleur
                      fieldLegendeLibelle
                    }
                  }
                }
                fieldZoneImage {
                  entity {
                    ... on MediaImage {
                      fieldMediaImage {
                        title
                        url
                      }
                    }
                  }
                }
                fieldZoneTexte {
                  processed
                }
              }
            }
          }
          fieldOffreZone8 {
            entity {
              ... on ParagraphZone8 {
                fieldZoneTitre
                fieldZoneConseil {
                  entity {
                    ... on ParagraphOffreConseil {
                      fieldConseilTitre
                      fieldConseilIcone {
                        entity {
                          ... on MediaImage {
                            fieldMediaImage {
                              title
                              url
                            }
                          }
                        }
                      }
                      fieldConseilTexte {
                        processed
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }`;

export default PRODUCT_DETAILS_QUERY;
