import React, { useState } from 'react';

import { Link } from 'gatsby';

import './productHero.scss';
import ShareIcon from 'images/icons/share-icon.svg';

import FacebookIcon from 'images/facebook-square-brands.svg';
import MessengerIcon from 'images/facebook-messenger-brands.svg';
import MailIcon from 'images/envelope-solid.svg';

import useWindowWidth from 'hooks/use-window-width';
import BlueCaliceoLogo from 'images/blue-caliceo-logo.svg';

const ProductHero = (props) => {
  const {
    centerObject, title, productId, universe, alias,
  } = props;
  const [windowWidth, setWindowWidth] = useState(0);

  useWindowWidth({ setWindowWidth });

  const [center, name] = centerObject?.name ? centerObject.name.split(' - ') : ['', ''];

  if (windowWidth < 769) {
    return (
      <div>
        <div className="offer-share-log--wrapper">
          <Link to="/">
            <img src={BlueCaliceoLogo} className="offer-header--logo" alt="Caliceo" />
          </Link>
          <button type="button" className="product-hero--share-btn">
            <div className="cont_icn_share">
              <a href={"https://www.facebook.com/sharer/sharer.php?u="+window.location.href} target="_blank"><img src={FacebookIcon} alt="Partager sur Facebook" /></a>
              <a href={"fb-messenger://share/?link="+window.location.href} target="_blank"><img src={MessengerIcon} alt="Partager sur Messenger" /></a>
              <a href={"mailto:?subject="+title+"&body="+window.location.href} target="_blank"><img src={MailIcon} alt="Partager par mail" /></a>
            </div>
            <div className="cont_btn_partage"><img src={ShareIcon} alt="Open share modal" /></div>
          </button>
        </div>
        <div className="container offer-hero--wrapper">
          <h2 className="offer-header-title">
            <span>
              {center}
              &nbsp;-
            </span>
            &nbsp;
            {name}
          </h2>
        </div>
      </div>
    );
  }

  return (
    <div className="container product-hero--wrapper">
      <div>
        <div className="offers-header--breadcrumbs--wrapper">
          <Link className="offers-link" to="/">{centerObject?.name}</Link>
          {'  >  '}
          <Link className="offers-link active-offer-link" to={`/offers/${universe?.name}`}>
            {universe?.name}
          </Link>
          {'  >  '}
          <Link className="offers-link active-offer-link" to={`/produit/${alias}`}>
            {title}
          </Link>
        </div>
        <h2 className="offers-header-title">
          Centre de
          {' '}
          <Link className="offers-link" to="/">
            {centerObject?.name}
          </Link>
        </h2>
      </div>
      <div className="cont_share">
        <p className="txt_share">Partager sur </p>
        <button type="button" className="product-hero--share-btn">
          <div className="cont_icn_share">
            <a href={"https://www.facebook.com/sharer/sharer.php?u="+window.location.href} target="_blank"><img src={FacebookIcon} alt="Partager sur Facebook" /></a>
            <a href={"fb-messenger://share/?link="+window.location.href} target="_blank"><img src={MessengerIcon} alt="Partager sur Messenger" /></a>
            <a href={"mailto:?subject="+title+"&body="+window.location.href} target="_blank"><img src={MailIcon} alt="Partager par mail" /></a>
          </div>
          <div className="cont_btn_partage"><img src={ShareIcon} alt="Open share modal" /></div>
        </button>
      </div>
    </div>
  );
};

export default ProductHero;
