import React, { useState, useEffect } from 'react';
import { Router, useParams } from '@reach/router';
import { APIWOI } from 'api';
import ProductDetails from 'components/product-details';

import { getCenterName } from 'utils/location';
import { centerCookieIds } from 'utils/constants';
import useSetCenterCookie from 'hooks/use-set-center-cookie';

const Produit = (props) => {
  const { location } = props;
  const [centerId, setCenterId] = useState('');
  const [nid, setNid] = useState('');
  const [alias, setAlias] = useState('');
  const centerName = getCenterName(location);

  const getPageId = (url) => {
    const urlFragments = url.pathname.split('/');
    setAlias(urlFragments[2]);
    const { cid } = centerCookieIds[`centre-${centerName}`];
    return APIWOI.get(`/fuopefjeh?path=${urlFragments[2]}&cid=${cid}`).then((res) => {
      if (res.data.status === 1) {
        setNid(res.data.responseData.id);
      }
    });
  };

  useEffect(() => {
    setCenterId(`centre-${centerName}`);
    getPageId(location);
  }, [location]);

  useSetCenterCookie(centerId);

  if (!centerId || !nid) {
    return null;
  }

  return (
    <Router path="/">
      <ProductDetails
        alias={alias}
        nid={nid}
        centerId={centerId}
        location={location}
        path="/produit/:produitId"
      />
    </Router>
  );
};

export default Produit;
