import React from 'react';
import { Modal } from 'antd';

import CaliceoButton from 'components/buttons/CaliceoButton';

const ReserveTimeModal = (props) => {
  const {
    showReserveTimeModal,
    setShowReserveTimeModal,
    setShowFirstStepBooking,
    setGoToNextStepBooking,
    addDirectlyToCart,
  } = props;

  const reserveNow = () => {
    setShowReserveTimeModal(false);
    setShowFirstStepBooking(true);
  };

  const reserveLater = () => {
    setShowReserveTimeModal(false);
    addDirectlyToCart();
  };

  return (
    <Modal
      className="offers-modal spa-booking-modal reserve-time-modal"
      visible={showReserveTimeModal}
      onCancel={() => setShowReserveTimeModal(false)}
      footer={null}
      width={800}
    >
      <div className="spa-booking-modal-container">
        <h1>Vous souhaitez ?</h1>
        <div className="d-flex align-items-center justify-content-center mt-4">
          <CaliceoButton
            primaryButton
            onClick={reserveNow}
            title="Voir les disponibilités et réserver"
          />
          <CaliceoButton
            primaryButton
            onClick={reserveLater}
            title="Acheter et réserver plus tard"
            classNames="secondary-caliceo--button ml-4"
          />
        </div>
      </div>
    </Modal>
  );
};

export default ReserveTimeModal;
