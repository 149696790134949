import React from 'react';
import { Divider } from 'antd';
import './seventhZone.scss';

import SeventhZoneImage from 'images/group-7-image.png';

const SeventhZone = (props) => {
  const { data } = props;

  if (!data?.entity.fieldZoneTitre) {
    return null;
  }

  return (
    <div className="seventh-zone--section">
      <div className="container">
        <Divider plain className="consumables--divider">
          {data.entity.fieldZoneTitre}
        </Divider>
        <div className="seventh-zone--legend--wrapper">
          {data.entity.fieldZoneLegende.map(({ entity }) => (
            <div className="seventh-zone--legend" key={entity.fieldLegendeCouleur}>
              <div style={{ background: entity.fieldLegendeCouleur }} className="seventh-zone--legend-color" />
              <div className="seventh-zone--legend-content">
                <p className="seventh-zone--legend-title">
                  {entity.fieldLegendeTitre}
                </p>
                <p className="seventh-zone--legend-label">
                  {entity.fieldLegendeLibelle}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className="seventh-zone--image--wrapper">
          <img src={data.entity?.fieldZoneImage?.entity?.fieldMediaImage.url} alt={data.entity.fieldZoneTitre} />
        </div>
        <div className="seventh-zone--legend-desc" dangerouslySetInnerHTML={{ __html: data.entity.fieldZoneTexte.processed}} />
      </div>
    </div>
  );
};

export default SeventhZone;
