import { useStaticQuery, graphql } from 'gatsby';

const useDuoTooltip = () => {
  const { caliceo } = useStaticQuery(graphql`
    query {
      caliceo {
        blockContentById(id:"16") {
          ...on caliceo_BlockContentSimple {
            fieldDescription {
              processed
            }
          }
        }
      }
    }
  `);

  return {
    tooltipContent: caliceo.blockContentById.fieldDescription.processed,
  };
};

export default useDuoTooltip;
