import React from 'react';
import { Divider } from 'antd';

import './sixthZone.scss';

const SixthZone = (props) => {
  const { selectedVariant, data } = props;
  if (!selectedVariant?.entity) return null;

  return (
    <div className="container">
      {data?.entity?.fieldZoneTitre && (
        <Divider plain className="consumables--divider">
          {data?.entity?.fieldZoneTitre}
        </Divider>
      )}
      <div className="consumables--row">
        {selectedVariant?.entity?.fieldConsommablesList.map(({ entity }) => {
          if (!entity.fieldAffichageFront) return null;
          return (
            <div className="consumable-card--wrapper" key={entity.fieldRefEntite.entity.title}>
              <div className="consumable-card--image--wrapper">
                <img src={entity.fieldRefEntite.entity.fieldImage.entity.fieldMediaImage.url} alt={entity.fieldRefEntite.entity.title} />
              </div>
              <div className="consumable-card--content">
                <div className="consumable-card--content-body">
                  <h3 className="consumable-card--title">
                    {entity.fieldRefEntite.entity.title}
                  </h3>
                  <div className="consumable-card--description" dangerouslySetInnerHTML={{ __html: entity.fieldRefEntite.entity.fieldDescription?.processed }} />
                </div>
                <div className="consumable-duration--addon">
                  <p className="main-desc">DURÉE {entity.fieldRefEntite.entity.fieldDureePrestation}</p>
                  <div dangerouslySetInnerHTML={{ __html: entity.fieldRefEntite.entity.fieldDeuxiemeDescription?.processed }} />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SixthZone;
